import { IFCIMeasurementOptions, IFCIResult, getFirstCPUIdle } from "@ms/rumone-fci/lib/index";
import { IMessenger, createClient } from "@ms/utilities-cross-window";
import { IReadonlyObservableValue, ObservableValue, useObservableArray } from "azure-devops-ui/Core/Observable";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { v4 } from "uuid";
import { EventContext } from "./common/contexts/event";
import { FeatureContext, IFeature } from "./common/contexts/feature";
import { LocaleStorageSettingsContext, SettingsContext } from "./common/contexts/settings";
import { ThemeContext } from "./common/contexts/theme";
import { useEventContextListener } from "./common/hooks/useeventcontextlistener";
import { useLogEngagementSession } from "./common/hooks/uselogengagementsession";
import { useObjectURL } from "./common/hooks/useobjecturl";
import { useSubscription, useSubscriptionArray } from "./common/hooks/useobservable";
import { getCookie, setCookie } from "./common/utilities/browser";
import { IFetchCompleteEvent, IFetchPrepareEvent } from "./common/utilities/fetch";
import { format } from "./common/utilities/format";
import { noop } from "./common/utilities/func";
import { parseNetworkError } from "./common/utilities/network";
import { ITelemetryEvent } from "./common/utilities/platformdispatch";
import { wait } from "./common/utilities/promise";
import { IScenarioDetails } from "./common/utilities/scenario";
import { getAccount } from "./photos/api/account";
import { sendFeedback } from "./photos/api/feedback";
import { hasError } from "./photos/api/network";
import { getFavorites2 } from "./photos/api/photo";
import { getProfile, getProfileImage } from "./photos/api/profile";
import { getUserEmailPreferences } from "./photos/api/useremailpreferences";
import { getUserPreferences } from "./photos/api/userpreferences";
import {
  IApiOrigin,
  IApiVersion,
  IDomainSettings,
  apiOriginCOB,
  apiOriginODC,
  apiOriginSandbox,
  apiVersionCOB,
  apiVersionODC,
  domainSettings
} from "./photos/api/util";
import { CustomerPromise } from "./photos/components/customerpromise/customerpromise";
import { AuthorizationContext, PhotoAuthorizationContext } from "./photos/contexts/authorization";
import { BackgroundContext } from "./photos/contexts/background";
import { DateContext, FormatDateContext } from "./photos/contexts/date";
import { EmbedContext, IEmbedContext } from "./photos/contexts/embed";
import { FavoritesContext } from "./photos/contexts/favorite";
import { PhotoFeatureContext } from "./photos/contexts/feature";
import { FeedbackContext, SendFeedbackContext } from "./photos/contexts/feedback";
import { MetaNavigation } from "./photos/contexts/metanavigation";
import { PhotoSearchContext, SearchContext } from "./photos/contexts/search";
import {
  ISessionContext,
  ISessionInformation,
  IUserProfile,
  SessionContext,
  defaultSessionInfo,
  getSignInUri,
  msaTenantId
} from "./photos/contexts/session";
import { PhotoThemeContext } from "./photos/contexts/theme";
import { ThrottleContext, useThrottleContext } from "./photos/contexts/throttle";
import { PhotoUserPreferencesContext, UserPreferencesContext } from "./photos/contexts/userpreferences";
import { WorkerContext } from "./photos/contexts/worker";
import { useAlbumApi } from "./photos/hooks/usealbumapi";
import { useFetch } from "./photos/hooks/usefetch";
import { useOneDS } from "./photos/hooks/useoneds";
import { extractError, useRejectionNoOp } from "./photos/hooks/userejection";
import { IAccount } from "./photos/types/account";
import { IFavoriteEvent } from "./photos/types/change";
import { IGraphProfile } from "./photos/types/profile";
import { UXSetting } from "./photos/types/settings";
import { IWorkerDetails, IWorkerMessage } from "./photos/types/worker";
import { IAuthenticateCommand, IEmbedOptions } from "./photos/types/xdm";
import { extractTelemetry } from "./photos/utilities/fetch";
import { filterUrl } from "./photos/utilities/util";
import { Shell } from "./shell";

import "./styles.ts";

import avatarImageUrl from "./public/static/media/avatar.png";

function performanceNow(): number {
  return performance && performance.now ? Math.round(performance.now()) : NaN;
}

/**
 * Timestamp representing the time the app started executing code.
 */
const appStartTimeInMs = Date.now();

// Session information is provided by the server baking in this object into the page HTML
// we read this out and use it to construct our session context
declare const window: Window &
  typeof globalThis & {
    SessionInformation?: ISessionInformation;
    cookieStore?: EventTarget & { get: (cookieName: string) => Promise<{ value: string } | null> };
  };

// Save the initial server sessionInformation into our local, the application
// acesses this information via the sessionContext. We will clear out the global
// to ensure code doesn't access it (it can become outdated).
const serverSessionInformation = window.SessionInformation;
window.SessionInformation = undefined;

// Generate a unique string for this session of the loaded application.
// This removes the need for the server based session id's.
const session = v4();

export interface IApplicationProps {
  workerDetails: IReadonlyObservableValue<IWorkerDetails>;
}

export function Application(props: IApplicationProps): React.ReactElement | null {
  // @CONVERGENCE: We've wrapped the main application component and renamed it to
  // ConvergedApplication to simplify the logic surrounding detecting and
  // marking a user as migrated

  // Get the details about the current request session.
  let sessionInformation: ISessionInformation = React.useMemo(() => {
    return { ...(serverSessionInformation ?? defaultSessionInfo) };
  }, []);

  const currentPage = React.useRef("");
  const previousPage = React.useRef("");

  // Before we start processing the page generation, if we need to update the
  // local route we will do that now. This MUST be done before the <BrowserRouter />
  // is mounted since it will base the page on the current path.
  if (sessionInformation.pathname) {
    window.history.replaceState(null, "", sessionInformation.pathname);
  }

  // We only process the DevMode_UserProfile cookie in a development build.
  // istanbul ignore next - we always test in "test" environment, we cover all code.
  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    const _userProfile = getCookie("DevMode_UserProfile");

    try {
      if (_userProfile) {
        const devModeProfile = JSON.parse(decodeURIComponent(_userProfile)) as {
          tenantId?: string;
          userId?: string;
          userProfile: IUserProfile;
        };

        // Update our session information with details parsed from the devmode userprofile.
        sessionInformation.tenantId = devModeProfile.tenantId;
        sessionInformation.userId = devModeProfile.userId;
        sessionInformation.userProfile = devModeProfile.userProfile;
      }
    } catch {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString());
      });

      window.location.pathname = "/";
    }
  }

  // Cache the refreshToken when the cookieStore is available.
  let refreshToken: string | undefined;
  if (window.cookieStore) {
    refreshToken = getCookie("RefreshToken");
    window.cookieStore.addEventListener("change", () => {
      window.cookieStore!.get("RefreshToken").then((cookie) => (refreshToken = cookie?.value));
    });
  }

  // Start with the default settings context, if the user is logged in we will
  // create a context specific to the user.
  const defaultSettingsContext = React.useContext(SettingsContext);

  // If the user is authenticated we want to create a settings context that
  // will save the settings for the authenticated user.
  const [settingsContext] = React.useState(
    sessionInformation.userId
      ? new LocaleStorageSettingsContext(`/${sessionInformation.userId}/`)
      : sessionInformation.clientVersion === "dev"
        ? new LocaleStorageSettingsContext("/__dev__/")
        : defaultSettingsContext
  );

  // @CONVERGENCE if we receive a migration exception, we will need to update and re-render
  const [migrationState, migrationDispatch] = React.useReducer(
    (state: { migrated: boolean }, action: { type: "migrate" }) => {
      switch (action.type) {
        case "migrate": {
          if (!state.migrated) {
            // These are idempotent, so its okay to call them from this
            // (even though this action handler should be pure)
            settingsContext.setSetting("userState", { migrated: true });
            return {
              migrated: true
            };
          }

          break;
        }
      }

      return state;
    },
    { migrated: settingsContext.getSetting<{ migrated?: boolean }>("userState")?.migrated ?? false }
  );

  // @CONVERGENCE Listen for the migration exception
  useEventContextListener("fetchComplete", detectMigratedUser);

  // Create the feature context from our settings and sessionInformation.
  const featureContext = React.useMemo(() => new PhotoFeatureContext(settingsContext, sessionInformation), [settingsContext, sessionInformation]);

  // Determine whether or not we are using a Sandbox development environment.
  const useSandbox = useSubscription(featureContext.featureEnabled("useSandbox"));

  const searchParams = React.useMemo(() => new URLSearchParams(window.location.search), []);
  const [additionalParameters] = React.useState(new URLSearchParams());

  // @TODO: Temporary work around for enabling the Home pivot when available on ODC.
  React.useMemo(() => {
    const ft = searchParams.get("ft");

    if (ft) {
      const features = ft.split(",");

      if (features.includes("homeEnable")) {
        featureContext.setFeatureEnabled("showHome", true);
      }
    }
  }, [featureContext, searchParams]);

  // Compute the embedded state of the application.
  // Process the embed options and apply any display options to the application.
  const embed = searchParams.get("embed");
  const { apiOriginEmbed, apiVersionEmbed, embedContext, embedDomainSettings } = React.useMemo(() => {
    let apiOriginEmbed: IApiOrigin | undefined;
    let apiVersionEmbed: IApiVersion | undefined;
    let embedContext: IEmbedContext = {};
    let embedDomainSettings: IDomainSettings | undefined;

    if (embed && typeof embed === "string") {
      try {
        const embedOptions = JSON.parse(embed) as IEmbedOptions;
        const { display, messaging, network, theme } = embedOptions;

        let hostClient: (IMessenger & { dispose: () => void }) | undefined;

        // Ensure the embed parameters tracked across navigations.
        additionalParameters.set("embed", embed);

        // The application should have the embedded css class on the body, when
        // opened in embedded mode.
        document.body.classList.add("embedded");

        // Setup the defaults for the embedded options, verify this is a supported value.
        embedOptions.theme = typeof theme === "string" ? theme : "light";

        if (display?.className && typeof display.className === "string") {
          document.body.classList.add(display.className);
        }

        // Setup our message channel if one is defined by the host.
        if (messaging) {
          const { channelId, origin } = messaging;

          if (channelId && origin && typeof channelId === "string" && typeof origin === "string") {
            hostClient = createClient({ channelId, origin });
          }
        }

        // If a custom vroom endpoint was specified update the configuration
        if (network?.endpoints?.vroom?.origin) {
          apiOriginEmbed = { ...apiOriginCOB };
          apiVersionEmbed = { ...apiVersionCOB };
          embedDomainSettings = { ...domainSettings };

          // Update the vroomOrigin with the supplied embedded origin.
          apiOriginEmbed.vroomOrigin = network.endpoints.vroom.origin;

          // Extract the hostname from Vroom API endpoint.
          const apiURL = new URL(apiOriginEmbed.vroomOrigin);
          embedDomainSettings[apiURL.hostname] = { scope: "Files.ReadWrite.All" };
        }

        // Now that we have successfully processed the embed parameters we will
        // expose it as the embed context.
        embedContext = { ...embedOptions, hostClient };
      } catch (err) {
        // Ignore any mis-configured embed options.
        // @TODO: Send telemetry about misconfigured embed options.
      }
    }

    return { apiOriginEmbed, apiVersionEmbed, embedContext, embedDomainSettings };
  }, [additionalParameters, embed]);

  // Ensure our theme is configured before we finish our first render pass.
  // If the user has a custom theme it will get configured when the ThemeContext
  // is initialized.
  document.body.setAttribute("data-theme", embedContext.theme || "light");

  // Dispose the host client when the application unmounts.
  React.useEffect(() => {
    return () => {
      embedContext?.hostClient?.dispose();
    };
  }, [embedContext]);

  useEventContextListener("scenarioComplete", (scenarioDetails: IScenarioDetails) => {
    if (scenarioDetails.scenarioName === "photosAppLoad") {
      embedContext.hostClient?.sendNotification({ notification: "navigation-ended", timestamp: scenarioDetails.endTime });
    }
  });

  useEventContextListener("scenarioStart", (scenarioDetails: IScenarioDetails) => {
    if (scenarioDetails.scenarioName === "photosAppLoad") {
      embedContext.hostClient?.sendNotification({ notification: "navigation-started", timestamp: scenarioDetails.startTime });
    }
  });

  const sessionContext = React.useMemo<ISessionContext>(() => {
    const migrated = migrationState.migrated;

    // The initial context will use the session userId for the driveId if it is available.
    // If the current driveId is not available or is set to "me" use the userId.
    let driveId = "me";
    const accountType = sessionInformation.accountType || (embed ? "business" : "consumer");

    if (accountType === "business" && sessionInformation.driveId) {
      driveId = sessionInformation.driveId;
    } else if (sessionInformation.userId) {
      driveId = sessionInformation.userId;

      // @HACK: The driveId is not 0 prefixed but the userId is, we will remove any prefix 0's
      if (!migrated) {
        while (driveId.charCodeAt(0) === 48) {
          driveId = driveId.substring(1);
        }
      }
    }

    return {
      ...sessionInformation,
      accountDetails: new ObservableValue<IAccount | undefined>(undefined),
      accountType,
      apiOrigin: apiOriginEmbed || (migrated ? (useSandbox ? apiOriginSandbox : apiOriginCOB) : apiOriginODC),
      apiVersion: apiVersionEmbed || (migrated ? apiVersionCOB : apiVersionODC),
      authenticated: () => {
        // If your embedded return true, the host will have to autheticate the client.
        if (sessionInformation.embedded) {
          return true;
        }

        // If there is a userProfile and a refresh token available the client is authenticated
        if (sessionInformation.userProfile && (refreshToken || getCookie("RefreshToken"))) {
          return true;
        }

        return false;
      },
      domainSettings: embedDomainSettings || domainSettings,
      driveId,
      getPage: () => ({ current: currentPage.current || window.location.pathname, previous: previousPage.current }),
      graphProfile: new ObservableValue<IGraphProfile | undefined>(undefined),
      locale: (sessionInformation.embedded ? sessionInformation.locale : sessionInformation.userProfile?.locale) || "en-us",
      migrated,
      profileUrl: new ObservableValue(avatarImageUrl),
      session: embedContext?.telemetry?.sessionId || session,
      setCurrentPage: (page: string) => {
        previousPage.current = currentPage.current;
        currentPage.current = page;
      },
      // Determine if this session is running in standalone mode (PWA).
      standalone: window.matchMedia && window.matchMedia("(display-mode: standalone)")?.matches,
      tenantType: sessionInformation.tenantId === msaTenantId ? "msa" : "aad",
      telemetryEndpoint: embedContext?.telemetry?.endpoint || sessionInformation.telemetryEndpoint
    };
    // Warns about refreshToken and sessionInfo, neither of which we actually
    // want to watch for (sessionInfo never changes and we only care to check
    // for the existence of a refresh token once)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [migrationState.migrated]);

  return (
    <BrowserRouter>
      <ThrottleContext.Provider value={useThrottleContext()}>
        <SettingsContext.Provider value={settingsContext}>
          <FeatureContext.Provider value={featureContext}>
            <EmbedContext.Provider value={embedContext}>
              <SessionContext.Provider value={sessionContext}>
                <MetaNavigation navigationParameters={additionalParameters}>
                  <MigrationAwareApplication {...props} searchParams={searchParams} />
                </MetaNavigation>
              </SessionContext.Provider>
            </EmbedContext.Provider>
          </FeatureContext.Provider>
        </SettingsContext.Provider>
      </ThrottleContext.Provider>
    </BrowserRouter>
  );

  /**
   * detectMigratedUser is used in @CONVERGENCE to switch users from using ODC
   * to using the new COB endpoints.
   *
   * @param event The result of a network request.
   */
  function detectMigratedUser(event: IFetchCompleteEvent): void {
    const url = new URL(event.url);

    // For failures on onedrive consumer VROOM endpoint we want to detect a
    // migrated user and restart the experience, we will store the migrated
    // state in local storage once we detect a migrated user.
    if (url.origin === apiOriginODC.vroomOrigin && event.result.status === "rejected") {
      if (hasError(event.result.reason, ["userContentMigrated"])) {
        migrationDispatch({ type: "migrate" });
      }
    }
  }
}

function MigrationAwareApplication(props: IApplicationProps & { searchParams: URLSearchParams }): React.ReactElement | null {
  const { searchParams, workerDetails } = props;

  const backgroundContext = React.useContext(BackgroundContext);
  const embedContext = React.useContext(EmbedContext);
  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const sessionContext = React.useContext(SessionContext);
  const settingsContext = React.useContext(SettingsContext);

  const formatDateContext = new FormatDateContext(sessionContext.locale);

  const settingsLoaded = React.useRef(false);

  // @CONVERGENCE whether or not the user has been migrated
  const migrated = sessionContext.migrated;

  // Determine whether or not the current session is authenticated.
  const userAuthenticated = sessionContext.authenticated();

  // Before a network request starts add our required authentication headers.
  // When a network request completes we will record the results in telemetry
  useEventContextListener("fetchEnd", extractTelemetry);
  useEventContextListener("fetchPrepare", prepareRequest);
  useEventContextListener("fetchComplete", recordNetworkTelemetry);

  // Create an observable array to track the set of active favorited photos.
  const [favorites] = React.useState<Set<string>>(new Set<string>());

  // Create an observable array that will contain the recent searches.
  // We will use the set function to update the recent searches when needed.
  const [recentSearches] = useObservableArray<string>([]);

  // When the array of searches changes we will update the setting on the service.
  useSubscriptionArray(recentSearches, () => {
    // Don't save until the setitngs have been loaded, and don't update when the
    // settings are loaded the first time.
    if (settingsLoaded.current) {
      userPreferencesContext
        .setUserPreferences("feature", {
          recentSearches: JSON.stringify(recentSearches.value)
        })
        .catch(noop);
    }
  });

  // Create our contexts from the photo feature implementation.
  const authorizationContext = new PhotoAuthorizationContext(eventContext, sessionContext, undefined, undefined, acquireHostToken);
  const searchContext = new PhotoSearchContext(eventContext, settingsContext, recentSearches);
  const themeContext = new PhotoThemeContext(settingsContext);
  const userPreferencesContext = new PhotoUserPreferencesContext(eventContext, settingsContext);

  // DEVELOPMENT: Show the telemetry in the console.
  if (process.env.NODE_ENV !== "test" && (sessionContext.environment === "development" || getCookie("Development"))) {
    eventContext.addEventListener("telemetryAvailable", consoleCallback);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      return () => {
        eventContext.removeEventListener("telemetryAvailable", consoleCallback);
      };
    });

    function consoleCallback(event: ITelemetryEvent) {
      console.debug(event.action, event);
    }
  }

  // Add an unhandled exception handler to capture exceptions and convert them
  // into telemetry events.
  window.addEventListener("error", unhandledException);

  // Add a message handler that responds to service worker messages.
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", handleWorkerMessage);
  }

  // Setup the usage of our telemetry system.
  const oneDSCallback = useOneDS();

  const telemetryCallback = (event: ITelemetryEvent) => {
    const filteredResult = filterResult(event);

    // Send the resulting event through the telemetry reporting systems.
    oneDSCallback && oneDSCallback(filteredResult);

    function filterResult(event: ITelemetryEvent): ITelemetryEvent {
      const filteredEvent: ITelemetryEvent = { ...event };

      // Ensure that when the event was successfully fulfilled we don't keep the
      // value, instead just record success. On a failure, we will record the
      // reason.
      if (event.result) {
        if (event.result.status === "fulfilled") {
          filteredEvent.result = { status: "fulfilled" };
        } else {
          filteredEvent.result = event.result;
        }
      }

      // We have some general requirements for filtering logging, some details can
      if (filteredEvent.url) {
        filteredEvent.url = filterUrl(new URL(filteredEvent.url, window.location.origin)).toString();
      }

      if (filteredEvent.children) {
        // Filter any children events before returning.
        const children: ITelemetryEvent[] = [];
        for (const childEvent of filteredEvent.children) {
          children.push(filterResult(childEvent));
        }

        filteredEvent.children = children;
      }

      return filteredEvent;
    }
  };

  useEventContextListener("telemetryAvailable", telemetryCallback);

  const albumApi = useAlbumApi();

  // Setup some of the network requests we will use to initialize the application.
  // Api for retrieving basic account details.
  const _getAccountDetails = useFetch(getAccount, { allowDuplicate: true, blocking: false, name: "getAccountDetails" });
  const _getAlbums = useFetch(albumApi.getAlbums, { blocking: false, name: "getAlbums" });
  const _getFavorites = useFetch(getFavorites2, { blocking: false, name: "getFavorites" });
  const _getProfile = useFetch(getProfile, { blocking: false, name: "getProfile" });
  const _getProfileImage = useFetch(getProfileImage, { blocking: false, name: "getProfileImage" });
  const _getUserEmailPreferences = useFetch(getUserEmailPreferences, { allowDuplicate: true, blocking: false, name: "getUserEmailPreferences" });
  const _getUserPreferences = useFetch(getUserPreferences, { allowDuplicate: true, blocking: false, name: "getUserPreferences" });
  const _sendFeedback = useFetch(sendFeedback, { blocking: false, name: "sendFeedback", parentScenario: null, timeoutMs: 10000 });

  // Create the context we will use to send feedback to service.
  const sendFeedbackContext = new SendFeedbackContext(_sendFeedback, sessionContext, eventContext, settingsContext);

  // Update the state of the psedo-loc feature as it is changed.
  useSubscription(featureContext.featureEnabled("pseudoLoc"), (enabled) => {
    setCookie("PseudoLoc", enabled ? "pseudo" : "");
  });

  // Update the UxSetting with the latest changes to the selected theme.
  useSubscription(themeContext.currentTheme, (theme) => {
    settingsContext.mergeSetting<UXSetting>("uxSetting", { themeId: theme.themeId });
  });

  // Ensure we have an explicit direction (dir=ltr|rtl) attribute for our page to ensure
  // we get the appropriate styling (default to ltr).
  try {
    // Determine if the browser supports text direction detection based on languange.
    const locale = new Intl.Locale(sessionContext.locale);
    const textInfo: { direction?: "ltr" | "rtl" } | undefined = (locale as any).textInfo;

    if (textInfo && textInfo.direction) {
      document.documentElement.dir = textInfo.direction;
    }
  } catch {}

  if (!document.documentElement.dir) {
    document.documentElement.dir = "ltr";
  }

  // Create an error handler for dealing with failed network requests. All calls
  // using this handler should be background calls and wont present any UX on
  // failure.
  const handleRejection = useRejectionNoOp();

  // Handle creating an object url for the profile image.
  const { createObjectURL } = useObjectURL();

  // Get the app settings at Boot level
  const uxSetting = settingsContext.getSetting<UXSetting>("uxSetting");
  const appSettings = getAppSettingsForTelemetry(uxSetting, featureContext.features);

  // Get the navigation performance data and include it in the page load telemetry.
  const navigationEntries = window.performance.getEntriesByType("navigation");
  const navigation = navigationEntries && navigationEntries[0] && navigationEntries[0].toJSON();

  // Filter the URL encoded in the navigation entry, this potentially contains
  // data that shouldn't be collected.
  if (navigation && navigation.name) {
    try {
      navigation.name = filterUrl(new URL(navigation.name)).toString();
    } catch {
      // Ignore errors since the name is not a valid URL.
    }
  }

  // Set of network calls that are not affected by your migrations status.
  React.useEffect(() => {
    if (userAuthenticated && !sessionContext.embedded) {
      _getProfile()
        .then((profile) => {
          sessionContext.graphProfile.value = profile;
        })
        .catch(handleRejection);

      // Retrieve the profile image for the user, if it returns null, it means
      // the user doesnt have a profile image setup.
      _getProfileImage("120x120")
        .then((value) => {
          if (value) {
            sessionContext.profileUrl.value = createObjectURL(value);
          }
        })
        .catch(handleRejection);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // If the user is authenticated, we need to read the users profile and add
    // it to the current session context. If this fails we will just ignore it
    // and go on without a graph profile. This may affect features downstream.
    if (userAuthenticated && !sessionContext.embedded) {
      // Setup the background from the persisted uxSetting.
      if (uxSetting?.backgroundImage) {
        backgroundContext.setBackground("shell", { photo: uxSetting.backgroundImage });
      }

      // Initialize the users details from the service.
      _getAccountDetails(sessionContext.driveId)
        .then((accountDetails) => {
          sessionContext.accountDetails.value = accountDetails;
        })
        .catch(handleRejection);

      // For now we need to make a separate call for email preferences
      _getUserEmailPreferences()
        .then((value) => {
          userPreferencesContext.emailPreferences.value = value;
        })
        .catch(handleRejection);

      const userPreferencesPromise = _getUserPreferences()
        .then((value) => {
          unstable_batchedUpdates(() => {
            if (value.photo) {
              settingsContext.setSetting("photoPreferences", value.photo);
              userPreferencesContext.photoPreferences.value = value.photo;
            }

            if (value.email) {
              settingsContext.setSetting("emailPreferences", value.email);
              userPreferencesContext.emailPreferences.value = value.email;
            }

            if (value.featureSettings) {
              settingsContext.setSetting("featurePreferences", value.featureSettings);
              userPreferencesContext.featurePreferences.value = value.featureSettings;

              // Store recent searches in observable array
              if (value.featureSettings.recentSearches) {
                // If we already have recentSearches it means people have done searches
                // before we loaded the recent. We will go ahead and cause an update
                // since we have new searches. The new searches will be added to the
                // start of the array.
                if (recentSearches.length) {
                  settingsLoaded.current = true;
                }

                recentSearches.splice(recentSearches.length, 0, ...(JSON.parse(value.featureSettings.recentSearches) as string[]));
                settingsLoaded.current = true;
              }
            }
          });

          return value;
        })
        .catch(handleRejection);

      // Load and manage the favorites for this session.
      // NOTE: Right now we only load the first page of favorites.
      _getFavorites()
        .then((favoritesPage) => {
          favoritesPage.value.forEach((favorite) => favorites.add(favorite));
        })
        .catch(handleRejection);

      // We want to hide the Albums pivot for converged users that DONT have any
      // existing albums until the new album support is available. This will load
      // this value up front to enable quick determination.
      if (migrated) {
        const albumsPromise = _getAlbums(sessionContext.driveId);

        // If the userPreferences show the user is in WithoutQuota or has albums
        // we will enable otherwise disable the albums feature for now.
        Promise.allSettled([userPreferencesPromise, albumsPromise]).then(([userPreferences, albums]) => {
          const albumsSupported =
            userPreferences.status === "fulfilled" &&
            userPreferences.value &&
            albums.status === "fulfilled" &&
            albums.value &&
            (userPreferences.value.featureSettings?.albumVersion === "WithoutQuota" || albums.value.value.length > 0);

          featureContext.setFeatureEnabled("showAlbums", !!albumsSupported);
        });
      }
    }

    // Cleanup network hooks each time we re-render.
    return () => {
      if (sessionContext.profileUrl.value) {
        window.URL.revokeObjectURL(sessionContext.profileUrl.value);
      }

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleWorkerMessage);
      }

      window.removeEventListener("error", unhandledException);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [migrated]);

  // Listen for dataChanged events and keep any application data up to date
  // with changes in the system.
  useEventContextListener("dataChanged", (event: IFavoriteEvent) => {
    if (event.changeType === "favoriteChange") {
      const { isFavorite, photoIds } = event.data;

      // If we are adding favorites, we need to add them to the favorites array
      // otherwise we need to remove them.
      for (let index = 0; index < photoIds.length; index++) {
        isFavorite ? favorites.add(photoIds[index]) : favorites.delete(photoIds[index]);
      }
    }
  });

  useLogEngagementSession();

  useMeasureTTI();

  // Determine if the request sent a login_hint for a user. If they are either
  // unauthenticated, or a hint for a different user, we will redirect through
  // AAD to pickup this login.
  const login_hint = searchParams.get("login_hint");
  if (login_hint) {
    const { userProfile } = sessionContext;
    if (!userAuthenticated || userProfile?.preferred_username !== login_hint) {
      window.location.href = getSignInUri(sessionContext, "lh", login_hint);

      // We are redirecting so we will just return nothing.
      return null;
    } else {
      const url = new URL(window.location.href);

      // If we had a login hint and didnt need to perform a login, we will strip
      // out the login_hint from the URL to prevent it from causing problems in
      // things like bookmarks later.
      url.searchParams.delete("login_hint");
      window.history.replaceState(null, "", url.toString());
    }
  }

  // @CONVERGENCE We use the users migrated state in the key for the Shell, this will
  // cause a new component instance to be created starting the entire load
  // process of the shell over again. This is important since the underlying
  // network calls will be routed to the new COB endpoints if we detect a
  // user has been migrated.
  return (
    <AuthorizationContext.Provider value={authorizationContext}>
      <WorkerContext.Provider value={{ workerDetails }}>
        <SettingsContext.Provider value={settingsContext}>
          <FeatureContext.Provider value={featureContext}>
            <ThemeContext.Provider value={themeContext}>
              <FeedbackContext.Provider value={sendFeedbackContext}>
                <FavoritesContext.Provider value={{ favorites }}>
                  <SearchContext.Provider value={searchContext}>
                    <UserPreferencesContext.Provider value={userPreferencesContext}>
                      <DateContext.Provider value={formatDateContext}>
                        <CustomerPromise
                          pillar="Boot"
                          perfGoal={4000}
                          completeCallback={(_, result) => {
                            // If we are embedded and have a hostClient available we will send the page load message.
                            if (embedContext.hostClient) {
                              const notification = {
                                error: result.status === "rejected" ? "Failed to load" : undefined,
                                notification: "page-loaded",
                                timestamp: Date.now()
                              };

                              embedContext.hostClient.sendNotification(notification);
                            }
                          }}
                          scenarioName="photosAppLoad"
                          startTime={navigation?.startTime}
                          properties={{
                            entryPoint: searchParams.get("sc"),
                            height: window.innerHeight,
                            navigation,
                            width: window.innerWidth,
                            ...appSettings
                          }}
                        >
                          <Shell key={`migration-state-${migrated}`} />
                        </CustomerPromise>
                      </DateContext.Provider>
                    </UserPreferencesContext.Provider>
                  </SearchContext.Provider>
                </FavoritesContext.Provider>
              </FeedbackContext.Provider>
            </ThemeContext.Provider>
          </FeatureContext.Provider>
        </SettingsContext.Provider>
      </WorkerContext.Provider>
    </AuthorizationContext.Provider>
  );

  function acquireHostToken(scope: string): Promise<string> {
    if (embedContext.hostClient) {
      const authenticateCommand: IAuthenticateCommand = {
        command: "authenticate",
        resource: sessionContext.apiOrigin.vroomOrigin,
        type: "SharePoint"
      };

      return embedContext.hostClient.ready.then(() => {
        return embedContext
          .hostClient!.sendCommand<IAuthenticateCommand>(authenticateCommand)
          .then((response: any /* NOTE: IResult is improperly typed */) => {
            if (response.result === "token") {
              return response.token;
            } else {
              return Promise.reject(response.error.message);
            }
          });
      });
    }

    return Promise.resolve("");
  }

  function handleWorkerMessage(event: ExtendableMessageEvent): void {
    const data: IWorkerMessage = event.data;

    if (data) {
      if (data.type === "tokenRequest") {
        const { resource } = data.event;
        let scope = data.event.scope;
        let supportsUMP: boolean | undefined = false;

        // If an origin is supplied we will translate that to a scope.
        if (resource) {
          const url = new URL(resource);
          const settings = sessionContext.domainSettings[url.hostname];

          if (settings) {
            scope = settings.scope;
            supportsUMP = settings.supportsUMP;
          }
        }

        authorizationContext
          .accessToken(scope)
          .then((accessToken) => {
            navigator.serviceWorker.controller?.postMessage({
              event: { accessToken, supportsUMP },
              replyTo: data.messageId,
              type: "tokenResponse"
            });
          })
          .catch((error) => {
            eventContext.dispatchEvent("telemetryAvailable", {
              action: "userAction",
              messageId: data.messageId,
              name: "tokenResponse",
              resultType: "Failure"
            });

            //
            // Convert the error into an object we can marshal to the service worker.
            //
            // NOTE: We can't marshal the response so remove it from the parsed
            // network error when it exists.
            const errorPromise =
              error instanceof Response
                ? parseNetworkError(error).then((networkError) => {
                    const { response, ...result } = networkError;
                    return result;
                  })
                : Promise.resolve(extractError(error));

            errorPromise.then((error) => {
              navigator.serviceWorker.controller?.postMessage({
                event: { error },
                replyTo: data.messageId,
                type: "tokenResponse"
              });
            });
          });
      } else if (data.type === "workerTelemetry") {
        eventContext.dispatchEvent(data.event.action === "fetchComplete" ? "fetchComplete" : "telemetryAvailable", {
          ...data.event,
          telemetryProperties: { ...data.event.telemetryProperties, serviceWorker: true }
        });
      }
    }
  }

  /**
   * Given the origin of the request make sure we have the proper authentication
   * credentials attached to the request before it starts. This may involve
   * refreshing our credential.
   */
  function prepareRequest(event: IFetchPrepareEvent): void {
    const { apiOrigin, apiVersion, domainSettings } = sessionContext;

    // Create a full headers object for the event.init and replace it.
    const headers = (event.init.headers = new Headers(event.init.headers));

    // First prepare the origin and any potential apiVersion segments
    event.url = format(event.url, { ...apiOrigin, ...apiVersion });

    // Create the URL that will be used for this network request after all
    // parameterization has been completed.
    const url = new URL(event.url);

    // URL's MAY contain a pre-existing accessToken from a paging request
    // or other form of server generated API url from a previous request.
    // This accessToken has a reasonable chance of being expired. We should
    // remove this accessToken and replace it with a fresh one, and use
    // the best form of authentication.
    url.searchParams.delete("access_token");
    url.searchParams.delete("ump");

    // Force all api to avoid intermediary caches unless the API has specified a
    // pre-existing cache policy. This can cause problems with CORS since they
    // may be returned without CORS headers.
    if (!event.init.cache) {
      event.init.cache = "no-cache";
    }

    // Add Scenario headers to SPO Vroom requests (consumer and business).
    if ((sessionContext.migrated || sessionContext.accountType === "business") && url.origin === apiOrigin.vroomOrigin) {
      if (!headers.has("Scenario")) {
        headers.set("Scenario", sessionContext.getPage().current);
      }
    }

    if (sessionContext.authenticated()) {
      // Lookup the configuration for the target domain.
      const configuration = domainSettings[url.hostname];

      // Make sure we keep any existing prefer directives along with our convergence values.
      // Don't add the migration directive to the search API it is still on ODC.
      if (url.origin === apiOriginODC.vroomOrigin && url.pathname.indexOf("/oneDrive.search(q=@s)") === -1) {
        const existingPrefer = url.searchParams.get("prefer");

        // If we don't have an existing Migration directive we will include one.
        if (!existingPrefer || existingPrefer.indexOf("Migration=") === -1) {
          url.searchParams.set("prefer", `${existingPrefer ? `${existingPrefer};` : ""}Migration=EnableRedirect;FailOnMigratedFiles`);
          event.url = url.toString();
        }
      }

      // If we have a preferred user profile language we will set it as the
      // accept-language header for all requests to the vroom endpoint.
      if (sessionContext.userProfile && (url.origin === apiOrigin.vroomOrigin || url.origin === apiOrigin.searchOrigin)) {
        headers.set("Accept-Language", sessionContext.userProfile.locale);
      }

      // Only add our Bearer token to the required domains, this will prevent
      // us from sending it to the wrong server. Don't override an existing
      // authorization header, this could be a badger etc.
      if (configuration && configuration.scope && !headers.get("Authorization")) {
        event.waitUntil(authorizationContext.accessToken(configuration.scope).then(processHeaders));
      } else {
        processHeaders();
      }

      function processHeaders(accessToken?: string) {
        // If the service supports the UMP (Unpack Multipart Post) protocol.
        // We will convert simple GET requests to POST's. This will avoid any
        // type of CORS pre-flights and not require access tokens in the URL.
        if (configuration.supportsUMP && (!event.init.method || event.init.method === "GET")) {
          let headerString = "";

          // If we have an accessToken ensure it is set in the request.
          if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
          }

          // Build up a header string based on the set of headers in the init.
          for (const header of headers.entries()) {
            headerString += `${header[0]}: ${header[1]}\r\n`;
          }

          // Add the ump parameter to the request.
          url.searchParams.set("ump", "1");
          event.url = url.toString();

          // We will convert image GET requests into a UMP (Unpack Mutipart Post).
          // Note this resets the incoming headers to just the Content-Type since
          // all existing headers are being written to the Part.
          event.init.method = "POST";
          event.init.headers = new Headers({ "Content-Type": "multipart/form-data;boundary=f15fc59f-6147-4e07-86c4-e1b7fa228be7" });
          event.init.body =
            "--f15fc59f-6147-4e07-86c4-e1b7fa228be7\r\n" +
            "Content-Disposition: form-data;name=data\r\n" +
            "X-HTTP-Method-Override: GET\r\n" +
            `${headerString}` +
            "\r\n\r\n" +
            "--f15fc59f-6147-4e07-86c4-e1b7fa228be7--";
        } else if (accessToken) {
          // If the request can be a "simple" CORS request we will include
          // the access_token in the URL. This will prevent the extra
          // pre-flight request that is not needed.
          if (configuration.supportsAccessParam) {
            // Make sure we SET the parameter the URL might have an outdated
            // token that was in the generated URL.
            url.searchParams.set("access_token", accessToken);
            event.url = url.toString();
          } else {
            headers.set("Authorization", `Bearer ${accessToken}`);
          }
        }
      }
    }
  }

  /**
   * Convert the completed network request into a telemetry event that can be send
   * to our external telemetry system. Ensure the event doesn't contain sensitive
   * data we don't want recorded.
   */
  function recordNetworkTelemetry(event: IFetchCompleteEvent): void {
    const { options, ...eventData } = event;

    eventContext.dispatchEvent("telemetryAvailable", { action: "fetchComplete", ...eventData });
  }

  /**
   * When an unhandled exception is caught we want to send the details through
   * to the telemetry system.
   *
   * @param event Details about the error, this will be a script error.
   */
  function unhandledException(event: ErrorEvent): void {
    const stack = event.error?.stack.toString();

    // Don't report well-known errors that are internal to the browser
    // ResizeObserver loop limit exceeded - https://bugs.chromium.org/p/chromium/issues/detail?id=809574
    if (event.message === "ResizeObserver loop limit exceeded") {
      return;
    }

    try {
      eventContext.dispatchEvent("telemetryAvailable", {
        action: "exception",
        colno: event.colno,
        error: event.message,
        filename: event.filename,
        lineno: event.lineno,
        name: "unhandledException",
        stack
      });
    } catch (err) {
      // We failed to report the exception just eat it and move on.
    }
  }
}

/**
 * use to prepare an object that contains all app level settings to be used for telemetry
 *
 * @param uxSetting The UX tracks all common settings in the "uxSetting" value
 * @param featureSetting The faeture availble to the user
 *
 * @returns An object of settings
 */
export function getAppSettingsForTelemetry(
  uxSetting: UXSetting | undefined,
  featureSetting: { [featureId: string]: IFeature } | undefined
): { [key: string]: any } {
  let appSettings = {} as { [key: string]: any };

  if (uxSetting) {
    appSettings = Object.keys(uxSetting).reduce((settings, key) => {
      settings["ux_" + key] = uxSetting[key as keyof UXSetting];
      return settings;
    }, appSettings);
  }

  if (featureSetting) {
    appSettings = Object.keys(featureSetting).reduce((settings, key) => {
      settings["feature_" + key] = featureSetting[key].featureEnabled.value;
      return settings;
    }, appSettings);
  }

  return appSettings;
}

export default Application;

/**
 * Maximum time in milliseconds we wait for TTI to fire - anything beyond that
 * results in a timeout.
 */
const ttiTimeoutInMs = 30000;

/**
 * Performance marker which is used as the start time for TTI timeout detection.
 */
const performanceMeasureStartTimeInMs = performanceNow();

/**
 * Transition type used by RumOne to specify a "full page load".
 */
const performanceFullPageLoadTransitionType = 4;

/**
 * Hook which measures TTI identical to other ODSP web apps.
 * When TTI is detected, we fire a RumOne performance event.
 */
function useMeasureTTI(): void {
  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  useEventContextListener("scenarioComplete", (scenarioDetails: IScenarioDetails) => {
    if (scenarioDetails.scenarioName === "photosAppLoad") {
      const firstMeaningfulPaintInMs = performanceNow();

      const fciPromise = measureFCI({
        measurementStartTime: firstMeaningfulPaintInMs,
        initialEntries: [],
        measureName: "TTI"
      });

      const elapsedTimeSincePerformanceMeasureStartInMs = Math.max(firstMeaningfulPaintInMs - performanceMeasureStartTimeInMs, 0);

      const timeoutPromise = wait(Math.max(ttiTimeoutInMs - elapsedTimeSincePerformanceMeasureStartInMs, 0), "timeout");
      const page = sessionContext.getPage().current || "Unknown";

      let navigationStart: number;
      let w3cResponseEnd: number;
      try {
        navigationStart = Number(performance.timing.navigationStart);
        w3cResponseEnd = Number(performance.timing.responseEnd);
      } catch (e) {
        navigationStart = NaN;
        w3cResponseEnd = NaN;
      }

      const commonProperties = {
        action: "performance",
        name: "AppLoad",
        ScenarioId: "ODCNext.Photos-" + page,
        PageTransitionType: performanceFullPageLoadTransitionType.toString(),
        FullPageOrOnePage: "true",
        duration: firstMeaningfulPaintInMs.toString(),
        appStart: (appStartTimeInMs - w3cResponseEnd).toString(),
        w3cResponseEnd: (w3cResponseEnd - navigationStart).toString()
      };

      Promise.race([timeoutPromise, fciPromise])
        .then((fciResult: IFCIResult | "timeout" | null) => {
          if (fciResult === "timeout") {
            eventContext.dispatchEvent("telemetryAvailable", {
              ...commonProperties,
              RUMOneError: JSON.stringify({
                reason: "TimeOut",
                details: {
                  MissingMetrics: ["TTI"]
                }
              })
            });

            return;
          }

          const fci = fciResult?.fci;

          eventContext.dispatchEvent(
            "telemetryAvailable",
            fci !== null && fci !== undefined
              ? {
                  ...commonProperties,
                  TTI: fci.toString()
                }
              : commonProperties
          );
        })
        .catch(() => {
          eventContext.dispatchEvent("telemetryAvailable", commonProperties);
        });
    }
  });
}

async function measureFCI(
  options: Pick<IFCIMeasurementOptions, "measurementStartTime" | "measureName" | "initialEntries">
): Promise<IFCIResult | null> {
  const _options: IFCIMeasurementOptions = {
    ...options,
    requiredMainThreadCPUIdleDurationInMilliseconds: 1000
  };

  return getFirstCPUIdle(_options);
}
